<div class="container footer text-muted">
  <div class="row">
    <div class="col">&copy; {{ year }} Vaultwarden.</div>
    <div class="col text-center"></div>
    <div class="col text-right">
      {{ "versionNumber" | i18n: version }}
      <br/>
      Fri Nov 18 06:46:14 UTC 2022
      <br/>
      1dbbdbd2a7d51fb93a59d22e8d4fbe891f0c22c9
    </div>
  </div>
</div>
